import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Students from "../pages/Students";
import Teachers from "../pages/Teachers";
import Blogs from "../pages/Blogs";
import Registration from "../pages/Registration";
import { useEffect } from "react";
import Team from "../pages/Team";

const Navigator = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever location changes
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/students" element={<Students />} />
      <Route path="/teachers" element={<Teachers />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/about/team" element={<Team />} />
    </Routes>
  );
};

export default Navigator;
