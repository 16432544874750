import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Heading from "./HighLighText";
import { useState } from "react";

type ContentInfoProps = {
  content: string;
  image: string;
  imagePos: "right" | "left";
  title: string;
  bg?: string;
  subtitle?: string;
  buttonText?: string;
  onClick?: any;
  showFullContent?: boolean;
};

const ContentInfo = ({
  content,
  image,
  imagePos,
  title,
  bg = "white",
  subtitle,
  buttonText,
  onClick,
  showFullContent = true,
}: ContentInfoProps) => {
  const displayHeading = useBreakpointValue({
    base: "displayOnTop",
    md: "displayBetween",
  });
  const [isExpand, setIsExpand] = useState(false);
  const isMobile = window.innerWidth < 768;
  const contentText = () => {
    if (!showFullContent && isMobile && !isExpand) {
      return content.slice(0, 120) + "...";
    }
    return content;
  };
  return (
    <Flex
      justifyContent={"space-around"}
      h={{ base: "100%", sm: "100%", md: "40vh", lg: 600 }}
      alignItems={"center"}
      flexDirection={{
        base: "column",
        md: imagePos === "right" ? "row-reverse" : "row",
      }}
      bg={bg}
      py={{ base: 8, md: 14, lg: 10 }}
    >
      {displayHeading === "displayOnTop" && (
        <Heading title={title} subtitle={subtitle} />
      )}
      <Box width={{ base: "70%", sm: "45%", md: "35%" }}>
        <Image src={image} />
      </Box>

      <Flex
        flexDirection={"column"}
        alignItems={{ base: "center", md: "flex-start" }}
        width={{ base: "85%", sm: "60%", md: "50%", lg: "35%" }}
        justifyContent={"center"}
      >
        {displayHeading === "displayBetween" && (
          <Heading title={title} subtitle={subtitle} />
        )}
        <Text
          // display={"flex"}
          fontSize={{ md: 19 }}
          fontFamily={"Urbanist-Medium"}
          mt={3}
          lineHeight={6}
          textAlign={{ base: "center", md: "left" }}
        >
          {contentText()}{" "}
          {!showFullContent && isMobile && content.length > 120 && (
            <Text
              onClick={() => setIsExpand(!isExpand)}
              color={"blue"}
              textDecoration={"underline"}
              fontSize={12}
            >
              {isExpand ? "Hide" : "show more..."}
            </Text>
          )}
        </Text>

        {buttonText && (
          <Button
            minWidth={{ base: "35%", md: "25%" }}
            bg={"#8E31FF"}
            mt={3}
            _hover={{ bg: "#DDC2FF" }}
            color={"white"}
            onClick={onClick}
            fontSize={{ base: 14, md: 16 }}
          >
            {buttonText}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ContentInfo;
