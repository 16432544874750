export const ForStudent =
  "SymphoMe provides a structured, gamified learning experience designed to enhance music skills through real-time feedback. Students can connect with teachers, monitor their progress, and prepare for ABRSM certification, all within a personalised learning pathway tailored to individual goals.";

export const ForTeacher =
  "SymphoMe offers a comprehensive learning management system tailored for music education professionals. Streamline your teaching process with automated student feedback, detailed progress tracking, and personalised recommendations. Effortlessly manage calendars, monitor student performance, and integrate with platforms such as Teams, Google Classroom, and Zoom to optimise your workflow.";

export const FrequentAskQuestionsData = [
  {
    question: "How do I get started with SymphoMe?",
    answer:
      "To get started, sign up via our website and download the app for personal use. For schools, our team will assits with the setup process, ensuring seamless integration of Symphome into your classroom enviroment.",
  },
  {
    question: "What does SymphoMe offer for music teachers?",
    answer:
      "SymohoMe provide a tailored learning management system for music education, allowing teachers to track student progress, offer personalised feedback, and automate recommendations. It integrates with platforms like Microsoft Teams, Google Classroom, and Zoom, simplifying lesson management and student engagement.",
  },
  {
    question: "Can SymphoMe help me prepare for music exams?",
    answer:
      "Yes, Symphome offers structured learning aligned with music certification programmes like ABRSM. The platform provides real-time feedback, helping students track progress and effectively prepare for their exams.",
  },
  {
    question: "Is SymphoMe suitable for group lessons?",
    answer:
      "Yes, Symphome is designed for both individual and group lessons. Teachers can manage multiple students, track progress, and provide tailored feedback, making it ideal for classroom or ensemble settings.",
  },
  {
    question: "How much does the SymphoMe pilot cost?",
    answer:
      "The Symphome pilot in schools is completely free for the first two months. This allows you to explore the platform and experience its full range of features at no cost during the trial period.",
  },
];
export const Partners = [
  {
    image: require("../assets/images/partner1.png"),
  },
  {
    image: require("../assets/images/partner2.png"),
  },
  {
    image: require("../assets/images/partner3.png"),
  },
  {
    image: require("../assets/images/partner4.png"),
  },
  {
    image: require("../assets/images/partner5.png"),
  },
  {
    image: require("../assets/images/partner8.png"),
  },
  // {
  //   image: require("../assets/images/partner7.png"),
  // },
];
