import { Box, Flex, Spacer } from "@chakra-ui/react";
import ContentInfo from "../components/ContentInfo";
import { StudentPageData } from "../constants/StudentPageData";
import Heading from "../components/HighLighText";

const Students = () => {
  return (
    <Box>
      <Spacer height={{base:20,md:28}} />
      <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
        <Heading title="Students" />
      </Flex>
      {StudentPageData.map((item, index) => {
        return (
          <ContentInfo
            key={index}
            content={item.content}
            title={item.title}
            image={item.image}
            imagePos={item.imagePos as any}
          />
        );
      })}
    </Box>
  );
};

export default Students;
