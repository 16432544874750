import { Box, Center, Flex, Text } from "@chakra-ui/react";
import LinkedinIcon from "../assets/svg/LinkedinIcon";
import InstagramIcon from "../assets/svg/InstagramIcon";
import TikTokIcon from "../assets/svg/TikTokIcon";
import FacebookIcon from "../assets/svg/FacebookIcon";
import SymphomeLogo from "../assets/svg/SymphomeLogo";
import DownloadButton from "./DownloadButton";

const socialIcons = [
  {
    Icon: LinkedinIcon,
    key: "linkedin",
    address: "https://www.linkedin.com/company/symphome/",
  },
  {
    Icon: InstagramIcon,
    key: "instagram",
    address: "https://www.instagram.com/symphome.app/ ",
  },
  {
    Icon: TikTokIcon,
    key: "tiktok",
    address: "https://www.tiktok.com/@symphome?_t=8rM5STfdBmS&_r=1",
  },
  {
    Icon: FacebookIcon,
    key: "facebook",
    address: "https://www.facebook.com/share/pTpFhSmKpc5xLzqV/?mibextid=LQQJ4d",
  },
];

const IconList = () => {
  return (
    <>
      {socialIcons.map(({ Icon, key, address }) => (
        <Icon
          key={key}
          cursor="pointer"
          width={35}
          height={35}
          onClick={() => window.open(address)}
        />
      ))}
    </>
  );
};

const Footer = () => {
  return (
    <Box
      borderColor={"black"}
      display={"flex"}
      flexDirection={"column"}
      bg={"#DDC2FF"}
      alignItems={"center"}
      mt={10}
    >
      <Flex
        px={6}
        py={4}
        bg={"primary"}
        w={{ base: "85%", md: "55%" }}
        h={"20%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        top={-8}
        position={"relative"}
        borderRadius={12}
      >
        <Box
          fontFamily={"Urbanist-SemiBold"}
          fontSize={{ base: 14, md: 18 }}
          textColor={"white"}
          w={"70%"}
        >
          Ready to get started?
        </Box>
        <DownloadButton />
      </Flex>

      <Flex
        w={"100%"}
        px={12}
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        order={{ base: 2 }}
      >
        <Flex
          flexDirection={"column"}
          gap={{ base: 6, md: 20 }}
          h={{ base: 150, md: 300 }}
          textAlign={{ base: "center", md: "left" }}
          alignItems={{ base: "center", md: "flex-start" }}
          justifyContent={"center"}
          borderColor={"black"}
          order={{ base: 1, md: 0 }}
        >
          <Flex
            gap={6}
            order={{ base: 2, md: 0 }}
            position={"relative"}
            bottom={{ base: 4, md: 0 }}
          >
            <IconList />
          </Flex>
          {/* <Box
            w={"45%"}
            textDecoration={"underline"}
            fontFamily={"Urbanist-SemiBold"}
            fontSize={12}
          >
            Terms & Conditions Privacy Policy Accessibility Statement
          </Box> */}
        </Flex>

        <Flex
          flexDirection={"column"}
          h={{ base: 200, md: 300 }}
          justifyContent={"center"}
          gap={2}
          alignItems={"center"}
        >
          <SymphomeLogo width={100} height={100} />
          <Text
            fontFamily={"Urbanist-SemiBold"}
            textColor={"#8E31FF"}
            fontSize={"2xl"}
          >
            Symphome
          </Text>
          <Text fontFamily={"Urbanist-SemiBold"} fontSize={18}>
            Your doorway to music education
          </Text>
        </Flex>
      </Flex>

      <Box
        position={"absolute"}
        bottom={2}
        fontFamily={"Urbanist-SemiBold"}
        fontSize={12}
      >
        © All Rights Reserved 2024. SymphoMe Limited, v1.0.2.0.
      </Box>
    </Box>
  );
};

export default Footer;
