import { Box, Flex, Text } from "@chakra-ui/react";

const Heading = ({
  title,
  subtitle,
  mobileFontSize = "1rem",
}: {
  title: string;
  subtitle?: string;
  mobileFontSize?: any;
}) => {
  const regex =
    /(<color>.*?<\/color>)|[\w\']+[\:\!\?\;\,\.\(\)\&\+\/]*|[\w\']+|[\:\!\?\;\,\.\(\)\&\+\/]+/g;
  const parts = title.match(regex);
  return (
    <Flex>
      <Box mb={4}>
        <Text
          alignItems={"center"}
          display={"flex"}
          fontSize={{ base: mobileFontSize, md: "1rem", lg: 22 }}
          fontFamily={"Urbanist-SemiBold"}
        >
          {parts?.map((segment, index) => {
            if (segment.startsWith("<color>") && segment.endsWith("</color>")) {
              const highlighText = segment.slice(7, -8);
              return (
                <Text key={index} color={"#8E31FF"}>
                  {highlighText}&nbsp;
                </Text>
              );
            }
            return <span key={index}>{segment}&nbsp;</span>;
          })}
        </Text>
        <Box
          borderWidth={0.7}
          borderColor={"#8E31FF"}
          width={"90%"}
          position={"relative"}
          left={1}
        />
      </Box>
      {subtitle && (
        <Text
          lineHeight={{ base: 2, lg: 10 }}
          fontFamily={"Urbanist-SemiBold"}
          fontSize={14}
        >
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};

export default Heading;
