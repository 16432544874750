import { SVGProps } from "react";

const Triangle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.1072 21.9074C20.9229 14.3372 17.0927 7.56122 11.9496 1.61021C10.8243 0.308447 8.9656 1.19057 8.45106 2.5338C6.35515 8.00205 3.03624 13.649 2.08963 19.4798C0.989624 19.6581 0.134676 21.1022 0.8436 22.0907C2.60094 24.5404 5.93091 24.4179 8.6754 24.5666C12.8087 24.791 16.9572 24.5694 21.0924 24.5514C22.4375 24.545 23.4863 23.2202 23.1072 21.9074ZM7.63712 20.5466C6.95583 20.4775 6.26809 20.4246 5.61214 20.2988C6.28698 18.4074 7.04335 16.5478 7.82275 14.6974C7.64817 16.6436 7.56756 18.5991 7.63712 20.5466ZM9.59069 20.6954C9.74822 18.4572 9.92234 16.224 10.2236 13.9987C10.4512 12.3182 11.3015 10.1062 10.7266 8.47098C10.6976 8.38853 10.634 8.32634 10.5838 8.25448C10.7427 7.88412 10.904 7.51515 11.0606 7.14388C11.3849 7.57135 11.6719 8.02001 11.9819 8.45578C11.7856 8.52718 11.6078 8.64049 11.5042 8.84824C10.7704 10.3149 11.3259 12.2031 11.4802 13.7679C11.7078 16.0697 11.916 18.3756 12.0975 20.6825C11.26 20.6936 10.4189 20.7268 9.59069 20.6954ZM13.7613 13.7679C13.7374 12.9258 13.7926 11.9898 13.7378 11.0819C14.052 11.5881 14.3445 12.1063 14.6411 12.6227C14.713 13.4726 14.8779 14.3229 14.9475 15.1834C15.0939 16.9942 15.005 18.8289 14.7586 20.6291C14.4361 20.6429 14.1275 20.6623 13.8032 20.6738C13.9115 18.3747 13.8263 16.0674 13.7613 13.7679ZM16.3607 20.5545C16.5869 19.3375 16.7343 18.1122 16.8107 16.8767C17.3386 18.0509 17.8357 19.2412 18.2645 20.4656C17.6463 20.496 16.995 20.5227 16.3607 20.5545Z"
        fill={props.color}
      />
    </svg>
  );
};
export default Triangle;