import { Box, Center, Flex, Spacer, Text } from "@chakra-ui/react";
import { PlatformInfo, TeacherPageData } from "../constants/TeacherPageData";
import ContentInfo from "../components/ContentInfo";
import Heading from "../components/HighLighText";
import Circle from "../assets/svg/Circle";
import { ReactNode } from "react";
import Triangle from "../assets/svg/Triangle";
import Star from "../assets/svg/Star";

const channels = [
  {
    icon: <Circle color={"#FF0000"} width={18} height={22} />,
    title: "Google Classroom",
  },
  {
    icon: <Triangle color={"#FFA500"} width={18} height={22} />,
    title: "Google Meet",
  },
  {
    icon: <Star color={"#008000"} width={18} height={22} />,
    title: "Teachers’ Community",
  },
  {
    icon: <Circle color={"#FF0000"} width={18} height={22} />,
    title: "Microsoft Teams",
  },
  {
    icon: <Triangle color={"#FFA500"} width={18} height={22} />,
    title: "Zoom",
  },
];

const IntegrationWith = ({
  title,
  icon,
}: {
  title: string;
  icon: ReactNode;
}) => {
  return (
    <Center flexDirection={"column"}>
      {icon}
      <Text fontSize={{ base: 9, md: 15 }}>{title}</Text>
    </Center>
  );
};

const Teachers = () => {
  return (
    <Box>
      <Spacer height={{base:20,md:28}} />
      <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
        <Heading title="Teachers" />
      </Flex>
      {TeacherPageData.map((item, index) => {
        return (
          <ContentInfo
            key={index}
            content={item.content}
            title={item.title}
            image={item.image}
            imagePos={item.imagePos as any}
            subtitle={item.subtitle}
          />
        );
      })}
      <Center
        alignItems={"center"}
        flexDirection={"column"}
        h={{ base: 400, md: "28vh", lg: 500 }}
      >
        <Heading title="Integration with multiple channels" />
        <Text w={{ base: "80%", md: "55%", lg: "35%" }} textAlign={"center"}>
          {PlatformInfo}
        </Text>
        <Flex mt={8} gap={{ base: 2, md: 3 }}>
          {channels.map((item, index) => {
            return (
              <IntegrationWith
                key={index}
                title={item.title}
                icon={item.icon}
              />
            );
          })}
        </Flex>
      </Center>
    </Box>
  );
};

export default Teachers;
