export const ApproachData = [
    {
        title: "We are Passionate About Education",
        description : "At SymphoMe, education is not just a profession; it's our passion. We believe in making high-quality music education accessible to all. Our dedication to innovation and transformative learning experiences reflects our deep commitment to empowering learners of all ages through technology. ",
        color: "#9A0000"
    },
    {
        title: "We are Committed to Excellence",
        description: "Excellence is at the core of our identity at SymphoMe. We are unwavering in our commitment to delivering nothing short of the best in music education. From the precision of our AI algorithms to the seamless user experience of our platform, every aspect reflects our dedication to excellence. ",
        color: "#0000FF"
    },
    {
        title: "We are a Community of Creatives",
        description: "SymphoMe is more than a platform; it's a vibrant community of creatives united by a shared love for music and innovation. Whether you're an audio signal processing expert, AI engineer, a music educator, or a creative designer, everyone in our community brings a unique perspective. ",
        color: "#008000"
    }
]

export const AboutUsData = [
    {
        title: "<color>Our</color> Team",
        content: "Meet the talented individuals driving innovation at SymphoMe. Our team of experts combines technology and music to revolutionise education, making high-quality music learning accessible to all.",
        imagePos: "right",
        image: require("../assets/images/team.png")
    },
    {
        title: "Join Us",
        content: "Passionate about music and technology? Join SymphoMe and be part of a dynamic team that’s shaping the future of music education with cutting-edge solutions.",
        imagePos: "left",
        image: require("../assets/images/joinUs.png")
    }
]