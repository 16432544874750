import { Box, Flex, Text } from "@chakra-ui/react";
import PlusIcon from "../assets/svg/PlusIcon";
import { useState } from "react";
import MinusIcon from "../assets/svg/Minus";

const FrequentAskQuestions = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <Flex
      w={"100%"}
      alignItems={"center"}
      flexDirection={"column"}
      my={4}
      onClick={() => setShowAnswer(!showAnswer)}
      cursor={"pointer"}
    >
      <Flex
        py={4}
        w={"85%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontFamily={"Urbanist-SemiBold"} fontSize={{ base: 14, md: 16 }}>
          {question}
        </Text>
        <Box w={8} h={8}>
          {showAnswer ? (
            <MinusIcon width={"100%"} height={"100%"} color="black" />
          ) : (
            <PlusIcon width={"100%"} height={"100%"} color="black" />
          )}
        </Box>
      </Flex>
      {showAnswer && (
        <Box width={"85%"} mb={16}>
          <Text fontSize={16}>{answer}</Text>
        </Box>
      )}
      <Box borderWidth={0.5} borderColor={"black"} width={"85%"} />
    </Flex>
  );
};
export default FrequentAskQuestions;
