export const TeacherPageData = [
  {
    content:
      "Streamline your teaching with SymphoMe’s tailored platform. Organise lessons, manage student data, and customise learning pathways for each student, all within an intuitive, centralised system built for music educators.",
    title: "<color>Learning Management System</color>",
    image: require("../assets/images/management.png"),
    imagePos: "left",
    subtitle: "For Music Education",
  },
  {
    content:
      "Provide instant, tailored feedback, or let SymphoMe’s AI deliver automatic, real-time guidance. Save time while ensuring students receive personalised insights that help them improve between lessons.",
    title: "<color>Feedback</color> System",
    image: require("../assets/images/feedback.png"),
    imagePos: "right",
    subtitle: "Manual and Automatic",
  },
  {
    content:
      "Efficiently manage your teaching schedule with SymphoMe’s built-in calendar. Keep track of lessons, assignments, and key dates, ensuring you stay organised and in control of your daily teaching activities.",
    title: "<color>Calendar</color> Management",
    image: require("../assets/images/calendar.png"),
    imagePos: "left",
  },
  {
    content:
      "Monitor student performance in real time. SymphoMe provides detailed insights into each student's progress, helping you identify areas for improvement and guide them towards their musical goals.",
    title: "Track <color>Student Progress</color>",
    image: require("../assets/images/trackProgress.png"),
    imagePos: "right",
  },
  {
    content:
      "Leverage SymphoMe’s AI as your virtual teaching assistant. Automate routine tasks, assist with lesson planning, and provide students with real-time feedback, allowing you to focus on higher-level teaching.",
    title: "<color>Virtual</color> Teaching Assistant",
    image: require("../assets/images/virtualTeaching.png"),
    imagePos: "left",
  },
];
export const PlatformInfo =
  " Easily integrate SymphoMe with platforms like Google Classroom, Teams, and Zoom. Deliver lessons seamlessly, connect with students remotely, and ensure a smooth, efficient teaching experience across multiple channels.";
