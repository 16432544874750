import { SVGProps } from "react";

const MinusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "42"}
      height={props.height || "38"}
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 19H32.0002" stroke={props.color} strokeWidth="5.81138" />
    </svg>
  );
};

export default MinusIcon;
