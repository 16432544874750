import { useState } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Heading,
  RadioGroup,
  Radio,
  Stack,
  Text,
  Spacer,
} from "@chakra-ui/react";
import axios from "axios";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [role, setRole] = useState("student");
  const [isRegistered, setIsRegistered] = useState(false);
  const [registrationError, setRegistrationError] = useState("");

  const registeredEmails = ["test@example.com", "user@example.com"]; // Example registered emails

  const validatePassword = (password: string) => {
    const minLength = 8;
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    return "";
  };

  // const userExists = async (email: string) => {
  //   try {
  //     const response = await axios.post(`${process.env.API_URL}/api/check-email`, { email });
  //     return response.data.exists;
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     return false;
  //   }
  // };

  const addUser = async (user: {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
    accessLevel: string;
  }) => {
    let params: any = { ...user };
    params.registrationType = "local";
    params.isLogin = false;
  
    const url = `${process.env.REACT_APP_API_URL}/api/users`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    const data = await response.json();
    return data;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (userExists(email)) {
    //   setRegistrationError("This email is already registered.");
    //   return;
    // }
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }
    // Handle registration logic here

    try {
      const user = {
        email,
        firstname,
        lastname,
        password,
        accessLevel: role
      };
      const data = await addUser(user);
      console.log('User added successfully:', data);
    } catch (error) {
      console.error('Error adding user:', error);
      setRegistrationError('Failed to register user.');
      return;
    }

    console.log("User registered:", {
      email,
      firstname,
      lastname,
      password,
      role,
    });
    setIsRegistered(true);
  };

  return (
    <Box>
      <Spacer h={100}/>
      <Heading as="h1" mb={6} ml={4}>
        Registration
      </Heading>
      {isRegistered ? (
        <Text fontSize="xl" color="green.500" ml={4}>
          Registration Complete
        </Text>
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          {registrationError && (
            <Text color="red.500" mb={4}>
              {registrationError}
            </Text>
          )}
          <FormControl id="email" isRequired mb={4} mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="firstname" isRequired mb={4} mt={4}>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </FormControl>
          <FormControl id="lastname" isRequired mb={4} mt={4}>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </FormControl>
          <FormControl id="password" isRequired mb={4} mt={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(validatePassword(e.target.value));
              }}
            />
            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
          </FormControl>
          <FormControl id="confirmPassword" isRequired mb={4} mt={4}>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(
                  password !== e.target.value ? "Passwords do not match" : ""
                );
              }}
            />
            {confirmPasswordError && (
              <p style={{ color: "red" }}>{confirmPasswordError}</p>
            )}
          </FormControl>
          <FormControl as="fieldset" mb={4} mt={4}>
            <FormLabel as="legend">Role</FormLabel>
            <RadioGroup onChange={setRole} value={role}>
              <Stack direction="row">
                <Radio value="student">Student</Radio>
                <Radio value="teacher">Teacher</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Button type="submit" mt={4} mb={4}>
            Register
          </Button>
          <br />
          <br />
          <br />
        </form>
      )}
    </Box>
  );
};

export default Registration;
