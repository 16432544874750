export const TeamMemberOverview = [
  {
    name: "Ifeanyichukwu",
    post: "Founder & CEO",
    profilePic: require("../assets/images/ifeanyi.png"),
    fullName: "Ifeanyichukwu Ezinmadu",
    description:
      "A graduate of music BMus (Hons) from the University of Edinburgh. A MasterCard Scholar at the University of Edinburgh (2018-2023) with a varied range of experience in the creative and business industry.                                             Ifeanyi's vision for SymphoMe is to bridge the gap between traditional music education and modern technological advancements, ensuring that high-quality music education is accessible to learners of all backgrounds. His dedication to innovation has earned him the Innovator Founder Status in the UK and has positioned SymphoMe at the forefront of the EdTech sector, establishing him as a recognised leader at the intersection of music and technology.",
  },
  {
    name: "Karol",
    post: "Co-founder & COO",
    profilePic: require("../assets/images/karol.png"),
    fullName: "Karol Fitzpatrick",
    description:
      "Karol is a music graduate, BMus (Hons), of Queen's University, Belfast and has an MSc in Education Management from the University of Edinburgh. She has worked for over 30 years in Scottish schools as a Director of Music and Head of Curricular Music. She also works for the Scottish Qualifications Authority as Principal Assessor for Advanced Higher Music.",
  },
  {
    name: "Yves",
    post: "Co-founder & CTO",
    profilePic: require("../assets/images/yves.png"),
    fullName: "Yves Segovia",
    description:
      "Yves is a veteran of the IT industry with over 15 years in application delivery for some of the largest IT companies in the world, such as Accenture, Logica, and AIG. He delivered multiple enterprise-grade, secure global applications from websites like accenture.com to applications interfacing with external entities like the Government of Rotterdam and the Federal Bank of NY. Now he’s using that extensive experience to push forward projects and startups that he believes in.",
  },
  {
    name: "Ema",
    post: "Animator",
    profilePic: require("../assets/images/ema.png"),
    fullName: "Ema Saric",
    description:
      "Ema is an animator and illustrator with a BA (Hons) from UAL London College of Communication. She’s spent over 10 years crafting vibrant animations and now channels her love for creativity, music, and education into SymphoMe, where fun and learning come together in harmony.",
  },
  {
    name: "Esther",
    post: "Education Designer",
    profilePic: require("../assets/images/esther.png"),
    fullName: "Esther Leung",
    description:
      "Esther graduated with a BMus Music (Hons) degree from The University of Edinburgh, with a particular focus on music education, community music, and piano performance. She has worked with and participated in organisations such as the Scottish Chamber Orchestra, the National Youth Choir of Scotland, and the former Dartington International Summer School — all of which aim to promote and foster musical growth and understanding in learners of all ages. Her experience in working primarily with children in educational settings, both in placements and private teaching, coupled with skills in curriculum design and digital content creation, enables her to craft engaging and accessible learning materials with the team at SymphoMe.",
  },
  {
    name: "Alfredo",
    post: "AI Engineer",
    profilePic: require("../assets/images/alfredo.png"),
    fullName: "Alfredo Zermini",
    description:
      "Alfredo is passionate about both music and science, earning a BSc and MSc in Physics, followed by a PhD in Audio and AI Engineering from the University of Surrey, where the research focused on innovative speech separation technology. The career path then advanced within the audio and music industry, including roles at companies such as Universal Music Group and Jabra. In addition to professional pursuits, Alfredo remains actively engaged in the creative aspects of music during personal time.",
  },
  {
    name: "Adam",
    post: "Junior AI Engineer",
    profilePic: require("../assets/images/adam.png"),
    fullName: "Adam Deedman",
    description:
      "Adam is a creative and skilled Music and Audio technologist, with an MSc in Sound and Music Computing from Queen Mary University of London and BSc (hons) in Music Technology from Birmingham City University.  At SymphoMe, Adam aims to combine his knowledge of cutting-edge audio AI techniques with his skills as a musician to innovate in the Music Education sector, helping to bring an exciting and accessible app to a wide audience of learners.",
  },
];

export const TeamDescription =
  "Meet the talented individuals driving innovation at SymphoMe. Our team of experts combines technology and music to revolutionism education, making high-quality music learning accessible to all.";
