import { SVGProps } from "react";

const Circle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "23"}
      height={props.height || "25"}
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.367 9.75946C20.9975 2.24689 12.9344 -1.40015 6.97012 2.64457C-3.46293 3.38228 -1.25961 23.2149 10.0537 24.5916C18.0658 25.5666 23.6544 16.818 22.367 9.75946ZM3.99825 12.028C3.87895 10.5401 4.14303 9.26839 4.7029 8.23308C4.71184 9.37161 4.92364 10.5664 4.97592 11.5486C5.07869 13.4829 5.26234 15.4034 5.60506 17.3011C4.64973 15.6625 4.13051 13.6822 3.99825 12.028ZM9.24986 20.3547C8.49561 20.1612 7.84591 19.7787 7.26548 19.3006C7.23688 16.7166 7.22214 14.1326 7.22973 11.5486C7.23376 10.2528 7.54743 8.6044 7.33384 7.19778C7.43304 7.14818 7.53224 7.11645 7.63054 7.04183C8.11356 6.67632 8.60553 6.32512 9.10642 6.00965C7.73823 9.79029 8.68551 14.9378 8.99382 18.7148C9.06621 19.6058 10.3267 19.6152 10.3848 18.7148C10.518 16.6469 10.438 14.5723 10.4648 12.5012C10.4961 10.095 11.1359 7.75855 11.1668 5.37828C11.1681 5.25763 11.1006 5.17318 11.0582 5.07443C11.5171 4.931 11.9894 4.86264 12.4711 4.85281C12.4165 4.9377 12.3299 4.98864 12.3013 5.09588C11.7266 7.24737 12.0242 9.7036 11.9384 11.9261C11.8348 14.6147 11.7159 17.2349 11.8419 19.9271C11.8562 20.2327 12.2856 20.298 12.3759 19.999C13.1574 17.4128 13.6453 14.881 14.0077 12.2063C14.3093 9.97841 14.8723 7.45202 14.3688 5.23083C14.4402 5.26121 14.5091 5.27372 14.581 5.30813C15.1257 5.56684 15.6132 5.92028 16.052 6.33137C15.0698 7.83049 14.9961 9.80146 14.8312 11.553C14.6078 13.9217 14.6641 16.344 14.8241 18.7144C14.8348 18.8712 14.8755 19.0048 14.934 19.1206C13.291 20.2998 11.2776 20.8757 9.24986 20.3547ZM16.7495 17.3842C16.837 15.7309 16.9005 14.0767 17.0337 12.4253C17.136 11.1594 17.4841 9.89887 17.7052 8.63881C18.1556 9.57715 18.4506 10.5883 18.5672 11.5633C18.8125 13.6049 18.0636 15.718 16.7495 17.3842Z"
        fill={props.color}
      />
    </svg>
  );
};
export default Circle;
