import { Box, Center, Flex, Spacer, Text } from "@chakra-ui/react";
import ContentInfo from "../components/ContentInfo";
import Heading from "../components/HighLighText";
import Circle from "../assets/svg/Circle";
import { ApproachData } from "../constants/AboutPageData";
import { AboutUsData } from "../constants/AboutPageData";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Approach = ({ item, index = 0 }: { item: any; index?: number }) => {
  return (
    <Box position={"relative"} left={{ base: 0, md: index * 10 }} mt={10}>
      <Flex gap={"10px"}>
        <Center h={{ base: 5, md: 5 }} position={"relative"} top={{ md: 2 }}>
          <Circle width={"100%"} height={"100%"} color={item.color} />
        </Center>
        <Box w={{ base: "100%", md: "80%" }}>
          <Text
            fontFamily={"Urbanist-SemiBold"}
            fontSize={{ base: 12, md: 20 }}
          >
            {item.title}
          </Text>
          <Text position={"relative"} fontSize={{ base: 13, md: 15 }}>
            {item.description}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Spacer height={{base:20,md:28}} />

      <Center>
        <Heading title="About Us" />
      </Center>

      <Center position={"relative"} my={{base: 10,md:20}}>
        <Center
          borderWidth={1}
          px={{ base: 4, md: 10 }}
          borderColor={"#8E31FF"}
          borderRadius={"3xl"}
          w={{ base: "80%", md: "70%" }}
          bg={"white"}
          position={"relative"}
          flexDirection={"column"}
          py={10}
        >
          <Heading title="Our Approach" />

          {ApproachData.map((item, index) => {
            return <Approach key={index} item={item} index={index} />;
          })}

          <Box
            borderRadius={"3xl"}
            position={"absolute"}
            w={"100%"}
            bg={"#6710DA"}
            zIndex={-1}
            h={"100%"}
            left={1.5}
            top={1.5}
            borderWidth={1}
          />
        </Center>
      </Center>

      <ContentInfo
        title={AboutUsData[0].title}
        content={AboutUsData[0].content}
        image={AboutUsData[0].image}
        imagePos={AboutUsData[0].imagePos as any}
        buttonText="Meet the Team"
        onClick={() => {navigate("/about/team")}}
      />

      <ContentInfo
        title={AboutUsData[1].title}
        content={AboutUsData[1].content}
        image={AboutUsData[1].image}
        imagePos={AboutUsData[1].imagePos as any}
        // buttonText="Visit our Careers Page"
      />
    </Box>
  );
};
export default AboutUs;
