import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import ContentInfo from "../components/ContentInfo";
import FrequentAskQuestions from "../components/FrequentAskQuestions";
import {
  Partners,
  FrequentAskQuestionsData,
  ForTeacher,
  ForStudent,
} from "../constants/HomePageData";
import { useNavigate } from "react-router-dom";
import DownloadButton from "../components/DownloadButton";

const Home = () => {
  const imageSrc = useBreakpointValue({
    base: require("../assets/images/homeBackgroundImage2.png"),
    md: require("../assets/images/homeBackgroundImage.png"),
  });
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        top={5}
        borderColor={"black"}
        // height={{base: 600,lg: "100%"}}
        // zIndex={-1}
        position={"relative"}
      >
        <Image
          objectFit="contain"
          width={"100%"}
          height={"100%"}
          zIndex={-1}
          src={imageSrc}
        />
        <Flex
          onClick={() => console.log("here it is")}
          cursor={"pointer"}
          position={"absolute"}
          top={"65%"}
          left={"5%"}
          zIndex={100}
          // borderWidth={2}
        >
          <DownloadButton bg="#8E31FF" color="white" />
        </Flex>
      </Box>

      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={{ base: 120, md: 140 }}
        bg={"#F1E7FF"}
        position={"relative"}
        top={-3}
      >
        <Flex
          fontFamily={"Urbanist-SemiBold"}
          justifyContent={"center"}
          fontSize={{ base: 18, md: 24 }}
        >
          Our Partners
        </Flex>
        <Flex gap={{ base: 3, md: 6, lg: 14 }} justifyContent={"center"} mt={2}>
          {Partners.map((item, index) => {
            return (
              <Box
                borderColor={"black"}
                width={{ base: "45px", sm: "58px", md: 20 }}
                height={{ base: "45px", sm: "58px", md: 20 }}
                key={index}
              >
                <Image height={"100%"} width={"100%"} src={item.image} />
              </Box>
            );
          })}
        </Flex>
      </Flex>

      <ContentInfo
        content={ForStudent}
        image={require("../assets/images/teacher1.png")}
        title="Student"
        imagePos="left"
        buttonText="Learn More"
        onClick={() => navigate("/students")}
        showFullContent={false}
      />
      <ContentInfo
        content={ForTeacher}
        image={require("../assets/images/teacher2.png")}
        title="Teacher"
        imagePos="right"
        bg="#F1E7FF"
        buttonText="Learn More"
        onClick={() => navigate("/teachers")}
        showFullContent={false}
      />

      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        my={"20"}
      >
        <Flex>
          <Text
            fontFamily={"Urbanist-ExtraBold"}
            fontSize={{ base: "3xl", md: "5xl" }}
          >
            FAQ
          </Text>
        </Flex>
        {FrequentAskQuestionsData.map((item, index) => {
          return (
            <FrequentAskQuestions
              question={item.question}
              answer={item.answer}
              key={index}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default Home;
