import { SVGProps } from "react";

const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || "41"}
      height={props.height || "38"}
      viewBox="0 0 41 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 19.2078H31.5002" stroke={props.color} strokeWidth="5.81138" />
      <path d="M20.293 30L20.1974 8" stroke={props.color} strokeWidth="5.81138" />
    </svg>
  );
};
export default PlusIcon;
