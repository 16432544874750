export const StudentPageData = [
    {
        content:"SymphoMe transforms music education into an interactive, fun experience with gamified lessons. Stay engaged, unlock achievements, and progress through levels as you enhance your musical skills.",
        title:"Enjoy <color>Gamified</color> Learning",
        image : require("../assets/images/learning.png"),
        imagePos: "left"
    },
    {
        content: "Receive instant, personalised feedback on your performance. SymphoMe’s advanced AI ensures you get accurate guidance to improve your music skills, keeping your learning journey effective and efficient.",
        title: "Learn with <color>Realtime Feedback</color>",
        image: require("../assets/images/feedback2.png"),
         imagePos: "right"
    },
    {
        content: "Seamlessly connect with music teachers for tailored lessons. Collaborate with educators who track your progress, offer real-time insights, and help you master your instrument or music theory.",
        title: "<color>Connect</color> with a Teacher",
        image: require("../assets/images/connectTeacher.png"),
         imagePos: "left"
    },
    {
        content: "Prepare for ABRSM exams with confidence. SymphoMe’s structured learning paths ensure you are ready for certification, offering all the tools and support you need to achieve your music goals.",
        title: "<color>Certification</color>",
        image: require("../assets/images/certification.png"),
         imagePos: "right"
    }
]