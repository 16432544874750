import {
  Box,
  Center,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Heading from "../components/HighLighText";
import { TeamMemberOverview, TeamDescription } from "../constants/TeamsData";
import LinkedinIcon from "../assets/svg/LinkedinIcon";
import { useState } from "react";

const Team = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentProfile, setCurrentProfile] = useState<number>(0);
  return (
    <Box>
      <Spacer h={20} />

      <Center>
        <Heading mobileFontSize={"1.3rem"} title="Meet the Team" />
      </Center>

      <Center py={8}>
        <Center w={"80%"} flexDirection={"column"} gap={6}>
          <Text fontFamily={"Urbanist-SemiBold"} fontSize={{ md: 20 }}>
            Creative, fun and diverse!
          </Text>
          <Text
            textAlign={"center"}
            fontFamily={"Urbanist-SemiBold"}
            fontSize={{ md: 18 }}
          >
            {TeamDescription}
          </Text>
        </Center>
      </Center>

      <Center>
        <Flex
          py={{ base: 4, md: 16 }}
          rowGap={{ base: 8, md: 16 }}
          flexWrap={"wrap"}
          w={"85%"}
          justifyContent={"center"}
          columnGap={{ base: 4, md: 0 }}
        >
          {TeamMemberOverview.map((item, index) => {
            return (
              <Box
                key={index}
                ml={{ base: 0, md: index !== 0 ? -8 : 0 }}
                mt={{ base: 0, md: index % 2 !== 0 ? 6 : 0 }}
                zIndex={index % 2 !== 0 ? 0 : 1}
                w={{ base: "45%", md: "26%", lg: "24%" }}
                h={{ base: "40%", md: "40%" }}
                position={"relative"}
              >
                <Box
                  position="relative"
                  w="full"
                  h="full"
                  overflow="hidden"
                  cursor={"pointer"}
                  onClick={() => {
                    onOpen();
                    setCurrentProfile(index);
                  }}
                  _hover={{
                    "&::after": {
                      content: '"Click to view more"',
                      fontFamily: "Urbanist-SemiBold",
                      fontSize: { base: 10, md: 14 },
                      position: "absolute",
                      top: 0,
                      zIndex: 1,
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      pointerEvents: "none",
                    },
                  }}
                  zIndex={1}
                  borderRadius={{ base: 10, md: 20 }}
                  pb={2}
                >
                  <Image src={item.profilePic} />
                  <Box
                    position={"absolute"}
                    w={"100%"}
                    h={"100%"}
                    bg={"#8E31FF"}
                    top={10}
                    zIndex={-1}
                    borderRadius={20}
                  />
                </Box>
                <Center flexDirection={"column"} mt={{ base: 4, md: 10 }}>
                  <Text
                    fontSize={{ base: 14, md: 16 }}
                    color={"#8E31FF"}
                    fontFamily={"Urbanist-SemiBold"}
                  >
                    {item.name}
                  </Text>
                  <Text
                    fontSize={{ base: 12, md: 14 }}
                    fontFamily={"Urbanist-SemiBold"}
                  >
                    {item.post}
                  </Text>
                </Center>
              </Box>
            );
          })}
        </Flex>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius={10}
          maxW={{ base: "90vw", sm: "75vw", md: "80vw", lg: "65%" }}
        >
          <ModalBody p={{ base: 10, md: 0 }} position={"relative"}>
            <Box
              display={{ base: "block", md: "none" }}
              position={"absolute"}
              top={2}
              right={2}
              onClick={onClose}
            >
              <CloseButton />
            </Box>
            <Flex flexDirection={{ base: "column", md: "row" }}>
              <Flex
                w={{ base: "100%", md: "40%", lg: 300 }}
                borderColor={"#8E31FF"}
                position={"relative"}
                borderRightWidth={{ md: 7 }}
                // h={"100%"}
              >
                <Image
                  src={TeamMemberOverview[currentProfile].profilePic}
                  // h={{ base: 300, md: "100%" }}
                  borderWidth={3}
                  h={{ base: 300, md: "100%" }}
                  borderStartRadius={{ base: 8, md: 10 }}
                  borderEndRadius={{ base: 8, md: 0 }}
                />
              </Flex>
              <Flex
                p={{ base: 0, md: 10 }}
                w={{ md: "70%" }}
                flexDirection={"column"}
              >
                <Flex mt={{ base: 4, md: 0 }} justifyContent={"space-between"}>
                  <Box>
                    <Text
                      fontFamily={"Urbanist-SemiBold"}
                      fontSize={{ md: 24 }}
                      color={"#8E31FF"}
                    >
                      {TeamMemberOverview[currentProfile].fullName}
                    </Text>
                    <Text fontFamily={"Urbanist-SemiBold"} fontSize={16}>
                      {TeamMemberOverview[currentProfile].post}
                    </Text>
                  </Box>
                  <LinkedinIcon width={30} color="#8E31FF" />
                </Flex>
                <Text mt={6} fontFamily={"Urbanist-SemiBold"}>
                  {TeamMemberOverview[currentProfile].description}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Team;
